import { createContext, useEffect, useState } from "react";
import { Dictionary } from "../types/Dictionary";
import { LocalizationCode } from "../types/locale/LocalizationCode";
import useSessionStorage from "../hooks/useSessionStorage";
import localeService from "../services/locale.service";

interface LocaleTranslation {
  locale: LocalizationCode;
  componentTranslations: Dictionary<Dictionary<string>>;
  setDateTime: Date;
}

export interface AppLocale {
  previous: LocaleTranslation;
  current: LocaleTranslation;
}

type LocaleContextType = {
  supportedLocales: LocalizationCode[];
  selectedLocale?: AppLocale;
  localeSwitchFailed: boolean;
  setUserLocale: (locale: LocalizationCode) => void;
  setComponentTranslations: (
    groupKey: string
  ) => Promise<Dictionary<string> | undefined>;
  setPreviousAppLocale: (keyGroup: string) => void;
};

export const defaultLocaleCode = {
  id: 1,
  code: "en-GB",
  displayName: "English GB",
  Country: { id: 1, name: "UK" },
};

const defaultLocale: LocaleTranslation = {
  locale: defaultLocaleCode,
  componentTranslations: {},
  setDateTime: new Date(),
};

const LocaleContext = createContext<LocaleContextType | null>(null);

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [supportedLocales, setSupportedLocales] = useState<LocalizationCode[]>(
    []
  );
  const [selectedLocale, setSelectedLocale] = useSessionStorage<
    AppLocale | undefined
  >("AppLocale", undefined);
  const [localeSwitchFailed, setLocaleSwitchFailed] = useState<boolean>(false);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const resp = await localeService.fetchSupportedLocales();
        setSupportedLocales(resp);
        let appLocale: AppLocale = {
          previous: defaultLocale,
          current: defaultLocale,
        };

        if (selectedLocale) {
          appLocale = selectedLocale;
        } else {
          setSelectedLocale(appLocale);
        }
      } catch (err) {
        console.error(err);
      }
    };

    sendRequest();
  }, []);

  const setUserLocale = (locale: LocalizationCode) => {
    setLocaleSwitchFailed(false);
    const currentDate = new Date();
    if (
      locale.code !== selectedLocale?.current.locale.code ||
      new Date(selectedLocale.current.setDateTime).getDate() <
        currentDate.getDate()
    ) {
      setSelectedLocale({
        previous: selectedLocale?.current ?? defaultLocale,
        current: {
          locale,
          componentTranslations: {},
          setDateTime: currentDate,
        },
      });
    }
  };

  const setComponentTranslations = async (
    groupKey: string
  ): Promise<Dictionary<string> | undefined> => {
    setLocaleSwitchFailed(false);
    try {
      if (selectedLocale) {
        const resp = await localeService.fetchTranslations(
          selectedLocale.current.locale.code,
          groupKey
        );
        selectedLocale.current.componentTranslations[groupKey] = resp;
        setSelectedLocale(selectedLocale);
        return resp;
      }
    } catch (err) {
      if (selectedLocale?.current.locale.id !== 1) {
        setLocaleSwitchFailed(true);
      }
      throw err;
    }
  };

  const setPreviousAppLocale = (keyGroup: string) => {
    if (
      selectedLocale &&
      selectedLocale.previous.componentTranslations[keyGroup]
    ) {
      setSelectedLocale({
        previous: defaultLocale,
        current: selectedLocale.previous,
      });
    }
  };

  return (
    <LocaleContext.Provider
      value={{
        supportedLocales,
        selectedLocale,
        setUserLocale,
        setComponentTranslations,
        setPreviousAppLocale,
        localeSwitchFailed,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;
